@tailwind base;
@tailwind components;
@tailwind utilities;

/* Add these lines to your main CSS file */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";


@import url('https://fonts.googleapis.com/css2?family=Afacad+Flux:wght@100..1000&family=Playwrite+DE+Grund:wght@100..400&display=swap');

body {
  font-family: "Afacad Flux", sans-serif;
}

html {
  scroll-behavior: smooth;
}