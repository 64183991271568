.clip-angled-transition-hero {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 5;
    clip-path: polygon(0 100%, 100% 90%, 100% 100%);
    background-color: rgb(243 244 246);
}

.border-angled-transition-hero {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
    clip-path: polygon(100% 89%, 100% 90%, 0 100%, 0 99%);
    background-color: rgb(96 165 250 / var(--tw-bg-opacity));
}